import { type FC } from 'react'

import getServerSidePropsHandler from '@redux/features/homePage/getServerSideProps'

import type { MainPageProps } from '@pages/MainPage'
import MainPage from '@pages/MainPage'
import { CUSTOMER_SUPPORT_LINK } from '@rnConstants/links/support'

export type Props = Omit<MainPageProps, 'customerSupportLink' | 'noIndex'>

const RootPage: FC<Props> = props => <MainPage {...props} customerSupportLink={CUSTOMER_SUPPORT_LINK} noIndex />

export const getServerSideProps = getServerSidePropsHandler

export default RootPage
